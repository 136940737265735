import React from 'react';
import Home from './Home'
import './App.css';

function App() {
  const retVal = (
    <div className="App">
      <Home></Home>
    </div>
  );
  return retVal;
}

export default App;
